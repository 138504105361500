/* eslint-disable no-bitwise */
import Cookies from "js-cookie";
import { json2csv } from "json-2-csv";
import moment from "moment";
import { PERMISSION_ACTIONS, permissionObj } from "./status";

export const readCookie = () => {
  const token = Cookies.get("token");
  const refreshToken = Cookies.get("refreshToken");
  const sessionTime: any = Cookies.get("sessionTime");

  return [token || "", refreshToken || "", sessionTime || ""];
};

export const setCookie = (
  token: string,
  refreshToken: string,
  sessionTime?: string | number | any,
) => {
  const isLocal = process.env.NEXT_PUBLIC_IS_LOCAL === "true";
  Cookies.set(
    "token",
    token,
    isLocal
      ? {}
      : {
          domain: ".intuions.com",
          secure: true,
        },
  );
  Cookies.set(
    "refreshToken",
    refreshToken,
    isLocal
      ? {}
      : {
          domain: ".intuions.com",
          secure: true,
        },
  );
  if (sessionTime) {
    Cookies.set(
      "sessionTime",
      sessionTime,
      isLocal
        ? {}
        : {
            domain: ".intuions.com",
            secure: true,
          },
    );
  }
};

export const clearStorage = () => {
  const isLocal = process.env.NEXT_PUBLIC_IS_LOCAL === "true";
  Cookies.remove(
    "token",
    isLocal
      ? {}
      : {
          domain: ".intuions.com",
          secure: true,
        },
  );
  Cookies.remove(
    "refreshToken",
    isLocal
      ? {}
      : {
          domain: ".intuions.com",
          secure: true,
        },
  );
  Cookies.remove(
    "sessionTime",
    isLocal
      ? {}
      : {
          domain: ".intuions.com",
          secure: true,
        },
  );
};

export const getToken = () => {
  const token = Cookies.get("token");
  return token ?? "";
};

export const getRefreshToken = () => {
  const token = Cookies.get("refreshToken");
  return token ?? "";
};

export const jsonToURI = (json: object) => encodeURIComponent(JSON.stringify(json));

export const uriToJSON = (uri: string) => JSON.parse(decodeURIComponent(uri));

export const getTariffName = (gridData: any, id: number): Record<string, any> =>
  gridData?.find((data: any) => data.id === id);

export const getBrandName = (listData: any, brand: string): Record<string, any> =>
  listData?.find((data: any) => data.value === brand);

export const getCountry = (countries: any, id: number): Record<string, any> =>
  countries?.find((country: any) => country.id === id);

export const getStatus = (ticketStatus: any, status: number): Record<string, any> =>
  ticketStatus?.find((statu: any) => statu.value === status);

export const getCountryName = (countries: any, name: string): Record<string, any> =>
  countries?.find((country: any) => country.name.toLowerCase() === name.toLowerCase());

export const getState = (countries: any, id: number): Record<string, any> => {
  let stateObj = {};
  /* eslint array-callback-return: "off" */
  countries?.find((country: any) => {
    /* eslint array-callback-return: "off" */
    country?.states?.find((state: any) => {
      if (state.gid === id) {
        stateObj = state;
      }
    });
  });
  return stateObj;
};

export const truncate = (str: string, length: number) => {
  const truncatedStr = str.substring(0, length);
  return `${truncatedStr}...`;
};

export const getStateName = (countries: any, name: string): Record<string, any> => {
  let stateObj = {};
  /* eslint array-callback-return: "off" */
  countries?.find((country: any) => {
    /* eslint array-callback-return: "off" */
    country?.states?.find((state: any) => {
      if (state.name === name) {
        stateObj = state;
      }
    });
  });
  return stateObj;
};

export const getVehicleTypeObj = (vehicleTypes: any[], value: string): Record<string, any> =>
  vehicleTypes?.find((vehicleType: any) => vehicleType.value === value);

export const getPlugTypeObj = (plugTypes: any[], value: string): Record<string, any> =>
  plugTypes?.find((plugType: any) => plugType.value === value);

export const removeEmptyObjProperties = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });
  return obj;
};

export const dateFormatter = (date: string | Date) => moment(date).format("DD/MM/YYYY hh:mm A");

export const secondsToHoursMinutes = (seconds: number) => {
  const hours = Math.floor(seconds / 3600); // 1 hour has 3600 seconds
  let remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60); // 1 minute has 60 seconds
  remainingSeconds = seconds % 60;

  return {
    hours,
    minutes,
    seconds: parseInt(remainingSeconds?.toFixed(0) || "0", 10),
    isZero: hours === 0 && minutes === 0 && parseInt(remainingSeconds?.toFixed(0) || "0", 10) === 0,
  };
};

export const findMinMaxValuesWithIndices = (array = []) => {
  let minValue = Infinity;
  let minIndex = -1;
  let maxValue = -Infinity;
  let maxIndex = -1;

  for (let i = 0; i < array.length; i++) {
    if (array[i] < minValue) {
      minValue = array[i];
      minIndex = i;
    }

    if (array[i] > maxValue) {
      maxValue = array[i];
      maxIndex = i;
    }
  }

  return {
    minValue: { value: minValue, index: minIndex + 1 },
    maxValue: { value: maxValue, index: maxIndex + 1 },
  };
};
export const DynamicIndex = (series: any) => {
  let dynamicIndex = 0;
  if (series[0]?.length > 0) {
    dynamicIndex = 0;
  } else if (series[1]?.length > 0) {
    dynamicIndex = 1;
  } else if (series[2]?.length > 0) {
    dynamicIndex = 2;
  }
  return dynamicIndex;
};

// eslint-disable-next-line no-promise-executor-return
export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const findMinMaxValuesWithIndicesAndAvg = (array = []) => {
  let minValue = Infinity;
  let minIndex = -1;
  let maxValue = -Infinity;
  let maxIndex = -1;
  let sum = 0;

  for (let i = 0; i < array.length; i++) {
    if (array[i] < minValue) {
      minValue = array[i];
      minIndex = i;
    }

    if (array[i] > maxValue) {
      maxValue = array[i];
      maxIndex = i;
    }

    sum += array[i];
  }

  const avgValue = sum / array.length;

  return {
    minValue: { value: minValue, index: minIndex > 0 ? minIndex + 1 : 1 },
    maxValue: { value: maxValue, index: maxIndex > 0 ? maxIndex + 1 : 1 },
    avgValue: avgValue?.toFixed(2),
  };
};

export const getDuration = (startDate: any, endDate: any) => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  const duration = moment.duration(endMoment.diff(startMoment, "milliseconds", true));

  return duration;
};

function isLeapYear(year: number) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export const dateDifference = (dateStr1: string, dateStr2: string) => {
  if (dateStr2 < dateStr1) {
    return {
      isExpired: true,
    };
  }
  // Convert strings to Date objects
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);

  // Calculate the time difference in milliseconds
  // @ts-ignore
  const timeDifference = date2 - date1;

  // Define the number of days in each month (leap year and non-leap year)
  const daysInMonth = [
    31, // January
    isLeapYear(date2.getUTCFullYear()) ? 29 : 28, // February
    31, // March
    30, // April
    31, // May
    30, // June
    31, // July
    31, // August
    30, // September
    31, // October
    30, // November
    31, // December
  ];

  // Calculate the difference in years, months, and days
  let yearsDifference = date2.getUTCFullYear() - date1.getUTCFullYear();
  let monthsDifference = date2.getUTCMonth() - date1.getUTCMonth();
  let daysDifference = date2.getUTCDate() - date1.getUTCDate();

  // Adjust negative differences
  if (daysDifference < 0) {
    monthsDifference--;
    daysDifference += daysInMonth[(date2.getUTCMonth() + 11) % 12]; // Adjust for the previous month
  }

  if (monthsDifference < 0) {
    yearsDifference--;
    monthsDifference += 12;
  }

  // Convert remaining milliseconds to different units
  const millisecondsDifference = timeDifference % 1000;
  const secondsDifference = Math.floor(timeDifference / 1000) % 60;
  const minutesDifference = Math.floor(timeDifference / (1000 * 60)) % 60;
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;

  // Create and return an object with the differences
  const timeObject = {
    isExpired: false,
    years: yearsDifference,
    months: monthsDifference,
    days: daysDifference,
    hours: hoursDifference,
    minutes: minutesDifference,
    seconds: secondsDifference,
    milliseconds: millisecondsDifference,
  };

  return timeObject;
};

export function isFloat(n: any) {
  return Number(n) === n && n % 1 !== 0;
}

export const getPercentage = (value: number, total: number) => {
  const per = ((value || 0) / (total || 1)) * 100;
  return isFloat(per) ? per?.toFixed(2) : per;
};

export const addAlpha = (color: string, opacity: number = 1) => {
  // coerce values so it is between 0 and 1.
  const hexOpacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
  return color + hexOpacity.toString(16).toUpperCase();
};

export const costAbbreviation = (val: number) => {
  let nVal: any = {
    value: val ? val.toFixed(2) : 0,
    unit: "₹",
  };
  if (val >= 10000000) {
    nVal = {
      value: (val / 10000000).toFixed(2),
      unit: "Cr", // crore
    };
  } else if (val >= 100000) {
    nVal = {
      value: (val / 100000).toFixed(2),
      unit: "Lac", // Lakh
    };
  }
  return nVal;
};

export const weightAbbreviation = (val: number) => {
  let nVal: any = {
    value: val ? val.toFixed(2) : 0,
    unit: "kg", // Kilogram
  };
  if (val >= 1000000000000000) {
    nVal = {
      value: (val / 1000000000000000).toFixed(2),
      unit: "Gt", // Gigatonne
    };
  } else if (val >= 1000000000000) {
    nVal = {
      value: (val / 1000000000000).toFixed(2),
      unit: "Mt", // Megatonne
    };
  } else if (val >= 1000000) {
    nVal = {
      value: (val / 1000000).toFixed(2),
      unit: "t", // Tonne
    };
  }
  return nVal;
};

export const energyAbbreviation = (val: number) => {
  let nVal: any = {
    value: val ? val.toFixed(2) : 0,
    unit: "kWh", // Kilowatt-hours
  };
  if (val >= 1000000000000) {
    nVal = {
      value: (val / 1000000000000).toFixed(2),
      unit: "TWh", // Terawatt-hours
    };
  } else if (val >= 1000000000) {
    nVal = {
      value: (val / 1000000000).toFixed(2),
      unit: "GWh", // Gigawatt-hours
    };
  } else if (val >= 1000000) {
    nVal = {
      value: (val / 1000000).toFixed(2),
      unit: "MWh", // Megawatt-hours
    };
  }
  return nVal;
};

export const liquidAbbreviation = (val: number) => {
  let nVal: any = {
    value: val ? val.toFixed(2) : 0,
    unit: "L", // Litres
  };
  if (val >= 1000000000) {
    nVal = {
      value: (val / 1000000000).toFixed(2),
      unit: "GL", // Gigalitres
    };
  } else if (val >= 1000000) {
    nVal = {
      value: (val / 1000000).toFixed(2),
      unit: "ML", // Megalitres
    };
  } else if (val >= 1000) {
    nVal = {
      value: (val / 1000).toFixed(2),
      unit: "kL", // Kilolitre
    };
  }
  return nVal;
};

export const handleDownloadCsv = async (jsonData: any, fileName: string = "data") => {
  const csvData = json2csv(jsonData);
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  const tempFileName = `${fileName}_${new Date().getTime()}.csv`;
  link.setAttribute("download", tempFileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const fixedDecimal = (value: number) => (isFloat(value) ? value?.toFixed(2) : value);

export const isNotNullOrUndefined = (value: any) =>
  typeof value !== "undefined" && value !== null && value !== undefined;

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

// Function to retrieve a value from an object based on a given path
export const getValueFromPath = (mainObj: Record<string, any>, path: string) => {
  if (mainObj) {
    const obj = JSON.parse(JSON.stringify(mainObj));
    // Check if both obj and path are provided
    if (obj && path) {
      try {
        // Split the path into keys
        const keys = path.split(".");
        let tempObj = obj;

        // Loop through each key in the path
        // eslint-disable-next-line no-restricted-syntax
        for (const key of keys) {
          // If tempObj is null, undefined, or not an object, return 0
          if (!tempObj || typeof tempObj !== "object") {
            return 0;
          }
          // Move to the next nested object using the current key
          tempObj = tempObj[key];
        }

        // Return the value found at the end of the path
        return tempObj;
      } catch (error) {
        // Catch any errors that occur and return 0
        return 0;
      }
    } else {
      // If either obj or path is not provided, return 0
      return 0;
    }
  }
  return 0;
};

// This function takes a sum of bits and separates it into individual bit positions that are set to 1.
export const separateBits = (sumOfBits: number) => {
  let tempSumOfBits = sumOfBits; // Store the input sumOfBits in a temporary variable
  const result = []; // Initialize an empty array to store individual bit positions
  let currentBit = 1; // Start with the least significant bit position (1)

  // Loop until all bits in tempSumOfBits are processed
  while (tempSumOfBits > 0) {
    // Check if the current least significant bit is set to 1
    if (tempSumOfBits & 1) {
      result.push(currentBit); // If true, push the current bit position to the result array
    }
    tempSumOfBits >>= 1; // Right shift tempSumOfBits to process the next bit position
    currentBit <<= 1; // Left shift currentBit to move to the next bit position
  }

  return result; // Return the array containing individual bit positions where bits are set to 1
};

/**
 * Checks if a given route has read permission based on the permission object.
 * @param permission The permission object containing route-specific permissions.
 * @param route The route string to check against the permission object.
 * @returns `true` if the route has read permission, otherwise `false`.
 */
export const checkRouteReadPermission = (permission: Record<string, any>, route: string) => {
  // Retrieve the permission path array for the specified route.
  const path = permissionObj[route];

  // Convert path to an array if it's not already an array.
  const pathArray = Array.isArray(path) ? path : [path];

  // Iterate through each path and check if it grants read permission.
  // eslint-disable-next-line no-restricted-syntax
  for (const str of pathArray) {
    // Retrieve permission bits for the current path and default to 0 if undefined.
    const permissionBits = getValueFromPath(permission || {}, str)?.p || 0;

    // Separate permission bits into an array of actions.
    const bitsArr = separateBits(permissionBits);

    // Check if the permission allows read action and return true if it does.
    if (bitsArr?.includes(PERMISSION_ACTIONS.READ)) {
      return true;
    }
  }

  // Return false if no path grants read permission.
  return false;
};

export const hasReadPermission = (permission: Record<string, any>, path: string) => {
  const permissionBits = getValueFromPath(permission || {}, path)?.p || 0;

  const bitsArr = separateBits(permissionBits);

  return bitsArr?.includes(PERMISSION_ACTIONS.READ);
};

export const Checkpermission = (path: any, action: any, permissions: any) => {
  const bitValue = getValueFromPath(permissions || {}, path);
  const isCreatePermission = separateBits(+bitValue);
  const isDisable = isCreatePermission.includes(action);
  return isDisable;
};

export const removeFalsyValues = (obj: any): any => {
  if (obj === null || obj === undefined) return obj;

  if (Array.isArray(obj)) {
    // Filter out falsy values and recursively clean up non-falsy values
    return obj
      .map(removeFalsyValues)
      .filter(
        (item) =>
          item !== undefined &&
          item !== null &&
          !(Array.isArray(item) && item.length === 0) &&
          !(typeof item === "object" && Object.keys(item).length === 0),
      );
  }
  if (typeof obj === "object") {
    const cleanedObj = Object.entries(obj).reduce((acc: any, [key, value]) => {
      const cleanedValue = removeFalsyValues(value);
      if (
        cleanedValue !== undefined &&
        cleanedValue !== null &&
        !(Array.isArray(cleanedValue) && cleanedValue.length === 0) &&
        !(typeof cleanedValue === "object" && Object.keys(cleanedValue).length === 0)
      ) {
        acc[key] = cleanedValue;
      }
      return acc;
    }, {});
    return cleanedObj;
  }

  // Return non-falsy values
  return obj || undefined;
};

/**
 * Reorders the keys of an object based on the specified key order.
 *
 * @param {Object} obj - The object whose keys are to be reordered.
 * @param {Array} keyOrder - The array specifying the new order of keys.
 * @returns {Object} - A new object with keys reordered according to the keyOrder array.
 */
export const reorderObjectKeys = (mainObj: Record<string, any>, keyOrder: string[]) => {
  const obj = JSON.parse(JSON.stringify(mainObj || {}));
  const reorderedObj: Record<string, any> = {};

  // Add the keys from keyOrder array
  keyOrder.forEach((key) => {
    if (key in obj) {
      reorderedObj[key] = obj[key];
    }
  });

  // Add any remaining keys that were not in the keyOrder array
  Object.keys(obj).forEach((key) => {
    if (!keyOrder.includes(key)) {
      reorderedObj[key] = obj[key];
    }
  });

  return reorderedObj;
};

export const removeDuplicatesFromArr = (arr: any[]) => {
  // Create a deep copy of the array
  const copiedArray = JSON.parse(JSON.stringify(arr));

  // Remove duplicates using Set
  const uniqueArray = [...new Set(copiedArray)];

  return uniqueArray;
};

export const deepEqualObject = (obj1: any, obj2: any) => {
  if (obj1 === obj2) {
    return true;
  }

  if (obj1 === null || obj2 === null) {
    return false;
  }

  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqualObject(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const powerAbbreviation = (val: number) => {
  const POWER_UNITS = [
    { threshold: 1_000_000_000_000, unit: "TW" }, // Terawatts
    { threshold: 1_000_000_000, unit: "GW" }, // Gigawatts
    { threshold: 1_000_000, unit: "MW" }, // Megawatts
    // { threshold: 1_000, unit: "kW" }, // Kilowatts
    // { threshold: 1, unit: "W" } // Watts
  ];

  // eslint-disable-next-line no-restricted-syntax
  for (const { threshold, unit } of POWER_UNITS) {
    if (val >= threshold) {
      const nVal = val / threshold;
      return {
        value: `${fixedDecimal(nVal)}`,
        unit,
      };
    }
  }

  // Fallback in case of unexpected values
  return {
    value: `${fixedDecimal(val)}`,
    unit: "kW", // Kilowatts
  };
};

export const mapSignalToPercentage = (signalStrength: number) => {
  let validSignalStrength = signalStrength;

  if (validSignalStrength < 0) validSignalStrength = 0;
  if (validSignalStrength > 30) validSignalStrength = 30;

  // Map 0-30 to 0-100
  return ((validSignalStrength / 30) * 100).toFixed(2) || 0;
};

export const censorEmail = (email: string, keepChars = 2) => {
  // Split the email into local part and domain part
  const [localPart, domainPart] = email.split("@");

  // If the local part is shorter than or equal to keepChars, return it as is
  if (localPart.length <= keepChars) {
    return email; // Do not censor if too short
  }

  // Get the first `keepChars` characters and calculate how many to replace
  const firstPart = localPart.slice(0, keepChars);
  const remaining = localPart.slice(keepChars);

  // Replace the remaining characters with asterisks
  const censoredLocalPart = firstPart + "*".repeat(remaining.length);

  // Return the censored email
  return `${censoredLocalPart}@${domainPart}`;
};

export const censorPhone = (phone: string, keepDigits = 4) => {
  // Normalize the phone number by removing non-digit characters
  const normalizedPhone = phone.replace(/\D/g, "");

  // Validate phone number length (considering typical lengths)
  if (normalizedPhone.length < keepDigits) {
    throw new Error("Invalid phone number format");
  }

  // Get the last `keepDigits` characters
  const visiblePart = normalizedPhone.slice(-keepDigits);
  const hiddenPart = normalizedPhone.slice(0, -keepDigits);

  // Replace hidden part with asterisks
  const censoredPhone = "*".repeat(hiddenPart.length) + visiblePart;

  return censoredPhone;
};

export const addToFormData = (formData: FormData, data: Record<string, any>) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      if (typeof data[key] === "object" && !(data[key] instanceof File)) {
        addToFormData(formData, data[key]);
      } else if (data[key] instanceof File) {
        // Do nothing for now, files will be appended later
      } else {
        formData.append(key, data[key]);
      }
    }
  }

  // Now append files
  // eslint-disable-next-line no-restricted-syntax
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key) && data[key] instanceof File) {
      formData.append(key, data[key]);
    }
  }
};

export function setValueAtPath(mainObj: any = {}, path: string = "", value: any = undefined) {
  const obj = JSON.parse(JSON.stringify(mainObj));
  // If obj is empty or not an object, initialize it as an empty object
  // if (typeof obj !== 'object' || Object.keys(obj).length === 0) {
  //   obj = {};
  // }

  // Split the path into an array of keys
  const keys = path.split(".");

  // Traverse the object using the keys array
  let current = obj;
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    // If the key doesn't exist or is not an object, create an empty object
    if (!current[key] || typeof current[key] !== "object") {
      current[key] = {};
    }
    // Move to the next level of the object
    current = current[key];
  }

  // Set the value at the final key
  if (value === undefined) {
    delete current[keys[keys.length - 1]];
  } else {
    current[keys[keys.length - 1]] = value;
  }

  // Return the modified object (though in JavaScript, objects are passed by reference,
  // so modifying `obj` inside the function modifies it directly)
  return obj;
}
export const formatDriveTime = (seconds: any) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  // Format the result as hh:mm:ss
  const formattedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0",
  )}:${String(secs).padStart(2, "0")}`;
  return formattedTime;
};
