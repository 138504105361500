import { checkRouteReadPermission } from "./general";
import { ROLE_SHORT_CODE } from "./status";

const ROLE_ROUTE_MAP: Record<string, string[]> = {
  "/dashboard": [ROLE_SHORT_CODE.OEM_ADMIN, ROLE_SHORT_CODE.OEM_EMPLOYEE],
  "/bms/dashboard": [ROLE_SHORT_CODE.BMS_ADMIN, ROLE_SHORT_CODE.BMS_EMPLOYEE],
  "/mcu/dashboard": [ROLE_SHORT_CODE.MCU_ADMIN, ROLE_SHORT_CODE.MCU_EMPLOYEE],
  "/dealer/dashboard": [ROLE_SHORT_CODE.DEALER_ADMIN, ROLE_SHORT_CODE.DEALER_EMPLOYEE],
  "/fleet/dashboard": [ROLE_SHORT_CODE.FLEET_ADMIN, ROLE_SHORT_CODE.FLEET_EMPLOYEE],
  "/ev-owner/dashboard": [ROLE_SHORT_CODE.EV_OWNER],
  "/driver/dashboard": [ROLE_SHORT_CODE.DRIVER],
};

const sidebarPageArr = [
  "/model-management/ev",
  "/model-management/bms",
  "/model-management/mcu",
  "/model-management/vcu",
  "/vehicle-management",
  "/component-management",
  "/bms/master-management",
  "/mcu/master-management",
  "/vcu/master-management",
  "/access-management",
  "/analysis",
  "/carbon-emission",
  "/dealer-management",
  "/fleet-management",
  "/ev-owner-management",
  "/fleet/driver-management",
  "/user-management",
  "/rule-management",
  "/geo-fencing",
  "/firmware-release",
  "/settings",
  "/profile",
  "/notification-history",
  "/command-history",
  "/hardware-log",
  "/audit",
  "/tickets",
];

export const defaultRedirect = (permissions: Record<string, any>, role: any = ""): string => {
  // Check dashboard pages first
  // eslint-disable-next-line no-restricted-syntax
  for (const [route, allowedRoles] of Object.entries(ROLE_ROUTE_MAP)) {
    if (checkRouteReadPermission(permissions, route) && allowedRoles.includes(role)) {
      return route;
    }
  }

  // If no dashboard page matches, check sidebar pages
  // eslint-disable-next-line no-restricted-syntax
  for (const page of sidebarPageArr) {
    if (checkRouteReadPermission(permissions, page)) {
      return page;
    }
  }

  // Default to an empty string if no routes match
  return "";
};
